import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState } from "react";

import Sidebar from "../src/components/Sidebar";
import Navbar from "../src/components/Navbar";
import Footer from "../src/components/Footer";

import Home from "./pages";
import Gallery from "./pages/gallery";
import Dashboard from "./components/Dashboard";
import Faq from "./components/Faq";
import Contact from "./components/Contact";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Router>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/gallery/" element={<Gallery />} exact />
          <Route path="/dashboard/" element={<Dashboard />} />
          <Route path="/faqs/" element={<Faq />} />
          <Route path="/contact-us/" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
