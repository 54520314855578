import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./index.css";
import AboutImg from "../../images/martin-bbq.jpeg";

const InfoSection = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <div className="row" id="about">
        <div className="column left" id="col1">
          <div className="text-container">
            <div className="card">
              <h4>It's Personal</h4>
              <p>
                With over 20 years catering experience, we will work with you to
                create a menu that fits your taste, budget and style.  From fine
                dining to canapés or even our hugely popular BBQ package.  This
                is your event, our aim is to make it one to remember
              </p>
            </div>
            <div className="card">
              <h4>Our Commitment</h4>
              <p>
                We use the freshest and tastiest ingredients, all from local
                suppliers in and around the area to create exceptional tasting
                food.  We cater for all dietary requirements, just let us know,
                we are more than happy to help, from gluten free, vegan and even
                Keto, we will make adjustments, the great thing is, it will
                match the rest of your party.
              </p>
            </div>
            <div className="card">
              <h4>Unique Style</h4>
              <p>
                Expert coordination, seamless service, this is your event, we
                will cater to your needs.  If there is any specific you want...
                no problem!
                <br />
                <br />
                We take pride in what we do, plenty of communication and
                discussion, from the moment you enquire, to booking, to the day
                of the event, we are there for you.
              </p>
            </div>
          </div>
        </div>

        <div className="column right" id="col2">
          <img src={AboutImg} alt="about-image" />
        </div>
      </div>
    </>
  );
};

export default InfoSection;
