import React, { useState } from "react";
import Video from "../../videos/burning-charcoal.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg
          playsInline={true}
          autoPlay={true}
          loop={true}
          muted={true}
          src={Video}
          type="video/mp4"
        />
      </HeroBg>
      <HeroContent>
        <HeroH1>Welcome to Convivio Dining</HeroH1>
        <br />
        <br />
        <HeroH1>
          Quality catering from the heart of Essex, London and beyond
        </HeroH1>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
