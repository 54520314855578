// CHANGE TO STYLED SHEETS
import "./index.scss";

import { useEffect, useState } from "react";
import { listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { ref } from "firebase/storage";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const Portfolio = () => {
  const imageListRef = ref(storage, "portfolio/");
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  const renderPortfolio = () => {
    return (
      <div className="images-container">
        <Gallery>
          {imageList.map((url, idx) => {
            return (
              <Item
                key={idx}
                original={url}
                // The size once clicked
                width="800"
                height="768"
              >
                {({ ref, open }) => <img ref={ref} onClick={open} src={url} />}
              </Item>
            );
          })}
        </Gallery>
      </div>
    );
  };

  return (
    <div className="container portfolio-page">
      <div>{renderPortfolio()}</div>
    </div>
  );
};

export default Portfolio;
