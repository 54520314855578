export const Data = [
  {
    id: 1,
    question: "What’s the best way to book?",
    answer:
      "Give us a call, message us on Facebook or use the “contact us” page, we will aim to get back to you asap, we usually reply straight away, or later in the day, but we will reply",
  },
  {
    id: 2,
    question: "How long are you there for?",
    answer:
      "Depending on the event, we would normally arrive 1-2 hours before the customer requires food to be served, then usually a further 1 hour from the time of service, but we are more than happy to stay longer if guests are wanting more food.",
  },
  {
    id: 3,
    question: "What do you supply?",
    answer:
      "Practically everything!... please check out the services pages for more details",
  },
  {
    id: 4,
    question: "Do you cater for dietary requirements?",
    answer:
      "100% yes, we take extra care when it comes to dietary needs, there is no reason a guest should miss out! Just let us know when making the booking of what the requirements are and for how many, sorted.",
  },
  {
    id: 5,
    question: "What happens to any left overs?",
    answer:
      "Any food that is remaining at the end of your event will be placed in foil disposable trays with lids and left with the customer.  But please note, we accept no responsibility for food holding/temperature control once the event has finished.",
  },
];
