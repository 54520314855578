import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoWrapper,
  MobileIcon,
  NavItem,
  NavLinks,
  NavLinksRouter,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavLinksPhone,
} from "./NavbarElements";
import { FaBars, FaPhoneAlt } from "react-icons/fa";
import logoImage from "../../images/cd-logo.svg";
import { useLocation } from "react-router-dom";
const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  let navbarNav;

  const location = useLocation();

  if (location.pathname === "/" || location.pathname === "/home") {
    navbarNav = (
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          {/* <NavLogo to="/" onClick={toggleHome}>
            </NavLogo> */}
          <NavLogoWrapper to="/" onClick={toggleHome}>
            <NavLogo src={logoImage} alt="Logo" />
          </NavLogoWrapper>
          Ï
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="about"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                About
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Services
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="reviews"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Reviews/ Testimonials
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinksRouter
                to="/gallery/"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Gallery
              </NavLinksRouter>
            </NavItem>
            <NavItem>
              <NavLinksPhone href="tel:07985133847">
                <FaPhoneAlt />
                &nbsp;07985133847
              </NavLinksPhone>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to="/contact-us/">Book Now</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    );
  } else {
    navbarNav = (
      <Nav>
        <NavbarContainer>
          <NavLogoWrapper to="/" onClick={toggleHome}>
            <NavLogo src={logoImage} alt="Logo" />
          </NavLogoWrapper>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinksRouter to="/#about">About</NavLinksRouter>
            </NavItem>
            <NavItem>
              <NavLinksRouter to="/#services">Services</NavLinksRouter>
            </NavItem>
            <NavItem>
              <NavLinksRouter to="/#reviews">
                Reviews/ Testimonials
              </NavLinksRouter>
            </NavItem>
            <NavItem>
              <NavLinksRouter
                to="/gallery/"
                className={({ isActive }) =>
                  "nav-link" + (isActive ? " activated" : "")
                }
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Gallery
              </NavLinksRouter>
            </NavItem>
            <NavItem>
              <NavLinksPhone href="tel:07985133847">
                <FaPhoneAlt />
                &nbsp;07985133847
              </NavLinksPhone>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to="/contact-us/">Book Now</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    );
  }

  return <>{navbarNav}</>;
};

export default Navbar;
