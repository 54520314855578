import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBFAaKP3DnMzC_sP8QG_CinIBoKeiKtnZk",
  authDomain: "convivio-dining-ff060.firebaseapp.com",
  projectId: "convivio-dining-ff060",
  storageBucket: "convivio-dining-ff060.appspot.com",
  messagingSenderId: "524664706795",
  appId: "1:524664706795:web:34e18447f31851224910ae",
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

export const auth = getAuth();
const provider = new GoogleAuthProvider();

export const db = getFirestore(app);

export const signInWithGoogle = () => signInWithPopup(auth, provider);
