import styled from "styled-components";

export const FaqPageWrapper = styled.div`
  background-color: #d6d6d6;
  text-align: center;
  padding-top: 30px;
  height: 80vh;
`;
export const FaqSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const FaqContainer = styled.div`
  position: absolute;

  @media screen and (min-width: 320px) {
    top: 30%;
  }

  @media screen and (min-width: 641px) {
    top: 45%;
    padding-top: 20px;
  }

  @media screen and (min-width: 961px) {
    top: 30%;
    width: 60%;
    padding-top: 0;
  }
`;
export const FaqWrap = styled.div`
  margin: 20px 20px;
`;
export const QuestionWrap = styled.div`
  background-color: #272727;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media screen and (min-width: 320px) {
    height: 40px;

    h1 {
      padding: 1rem;
      font-size: 0.7rem;

      span {
        padding-right: 10px;
        color: #fff;
        left: 0;
      }
    }
    span {
      margin-right: 1.5rem;
    }
  }

  @media screen and (min-width: 641px) {
    height: 50px;

    h1 {
      padding: 1.3rem;
      font-size: 1rem;

      span {
        padding-right: 10px;
        color: #fff;
      }
    }
    span {
      margin-right: 1.5rem;
    }
  }
`;
export const AnswerDropdown = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  @media screen and (min-width: 320px) {
    p {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 641px) {
    p {
      font-size: 1rem;
    }
  }
`;
