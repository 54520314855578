import styled from "styled-components";
import { MdFormatQuote } from "react-icons/md";

export const RecommendationsContainer = styled.div`
  background: #fff;
  display: flex;
  position: relative;
  justify-content: center;
  padding: 0 30px;

  @media screen and (min-width: 320px) {
    padding-top: 20%;
  }
  @media screen and (min-width: 641px) {
    padding-top: 10%;
    height: 700px;
  }
  @media screen and (min-width: 768px) {
    height: 80vh;
    padding-top: 0;
  }
`;

export const RecommendationsContent = styled.div`
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 320px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media screen and (min-width: 641px) {
    padding: 8px 24px;
  }
`;

export const RecommendationsH1 = styled.h1`
  color: #000;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const RecommendationsCard = styled.div`
  background: #ebebeb;
  align-items: center;
  border-radius: 10px;
  padding: 30px;
  margin: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;

  @media screen and (min-width: 320px) {
    width: 350px;
  }
  @media screen and (min-width: 641px) {
    width: 650px;
  }
  @media screen and (min-width: 961px) {
    width: 1000px;
  }
`;

export const RecommendationsReviewTime = styled.p`
  margin-top: 15px;
  color: #000;
  font-size: 12px;
  text-align: center;

  @media screen and (min-width: 320px) {
    font-size: 10px;
  }
  @media screen and (min-width: 641px) {
    font-size: 8px;
  }
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;

export const RecommendationsReviewText = styled.p`
  margin-top: 15px;
  color: #000;
  text-align: center;
  padding-bottom: 5%;

  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  font-style: italic;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;

  @media screen and (min-width: 320px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media screen and (min-width: 641px) {
    font-size: 10px;
    line-height: 16px;
  }
  @media screen and (min-width: 961px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const LeftQuote = styled(MdFormatQuote)`
  font-size: 5rem;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(180deg);
`;
export const RightQuote = styled(MdFormatQuote)`
  font-size: 5rem;
  position: absolute;
  right: 0;
  bottom: 0;
`;
