import React, { useState, useEffect } from "react";
import {
  RecommendationsContainer,
  RecommendationsContent,
  RecommendationsH1,
  RecommendationsReviewTime,
  RecommendationsReviewText,
  RecommendationsCard,
  LeftQuote,
  RightQuote,
} from "./RecommendationsElements";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Recommendations = () => {
  const [reviewsList, setReviews] = useState([]);

  useEffect(() => {
    const loadReviews = async () => {
      const response = await window.FB.api(
        "/convivodining/ratings",
        "GET",
        {
          access_token:
            "EAASjxGQBTZCgBADKniap9UOZBHUFZCZCvqIoFyZBzLjZBzN4lhclOOSsgWwlpkKD5zhKyoQSPQeLghLBVCSAKpWsDeZCiR51Gbgm74vl9RXGXRg1Ui73G7OXbZCDAGAA7INIH95khVkwqdmUELRPg8RHY373au6fYWOKAwJG0teM66v4TPFn0HQM",
          fields: "created_time,review_text",
        },
        function (response) {
          setReviews(response.data);
        }
      );
    };

    loadReviews();
  }, []);

  return (
    <RecommendationsContainer id="reviews">
      <RecommendationsContent>
        <RecommendationsH1>Reviews</RecommendationsH1>
        <RecommendationsCard>
          <LeftQuote />

          {reviewsList.length > 0 && (
            <Carousel
              infiniteLoop
              autoPlay
              stopOnHover={false}
              interval={8000}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
            >
              {reviewsList.map((rev) => (
                <>
                  <RecommendationsReviewTime>
                    {rev.created_time.slice(0, 10)}
                  </RecommendationsReviewTime>
                  <RecommendationsReviewText>
                    {rev.review_text}
                  </RecommendationsReviewText>
                </>
              ))}
            </Carousel>
          )}
          <RightQuote />
        </RecommendationsCard>
      </RecommendationsContent>
    </RecommendationsContainer>
  );
};

export default Recommendations;
