import React from "react";
import "./Popup.css";
import { FiX } from "react-icons/fi";

function Popup(props) {
  const handleParentClick = (event) => {
    event.preventDefault();

    if (event.target === event.currentTarget) {
      props.setTrigger(false);
    }
  };

  return props.trigger ? (
    <div className="popup" onClick={handleParentClick}>
      <div className="popup-inner">
        <FiX className="close-popup" onClick={() => props.setTrigger(false)} />
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}

export default Popup;
