import React from "react";
import Popup from "../Popup/Popup";
import Icon2 from "../../images/services/private-dining-services.png"; // private dining
import Icon3 from "../../images/services/canape-services.png"; // canape
import Icon4 from "../../images/services/koftas-fire.jpg"; // bbq
import Icon5 from "../../images/services/gazebo-corporate-services-small.jpg"; // corporate
import {
  ServicesContainter,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesTextContainer,
  ServicesH1,
  ServicesH2,
  ServicesP,
  ServicesPopupTitle,
  ServicesPopupDescription,
  ServicesPopupDinnerDescription,
  ServicesPopupBbqDescription,
  ServicesPopupListContainer,
  NavLinksRouter,
  ServicesPopupDisclaimer,
} from "./ServicesElements";

import { useState } from "react";

const Services = () => {
  const [buttonPopup1, setButtonPopup1] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);

  if (buttonPopup1 || buttonPopup2 || buttonPopup3 || buttonPopup4) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.removeProperty("overflow");
  }

  return (
    <ServicesContainter id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard onClick={() => setButtonPopup1(true)}>
          <ServicesIcon src={Icon2} />
          <ServicesTextContainer>
            <ServicesH2>Private Dining</ServicesH2>
            <ServicesP>
              A beautiful, three course meal, all cooked and served in the
              comfort of your own home, by your very own private chefs.
            </ServicesP>
          </ServicesTextContainer>
        </ServicesCard>
        <Popup trigger={buttonPopup1} setTrigger={setButtonPopup1}>
          <ServicesPopupTitle>Private Dining</ServicesPopupTitle>
          <ServicesPopupDinnerDescription>
            <ServicesPopupListContainer>
              <h3>Starters</h3>
              <ul>
                <li>
                  Seared scallops, cauliflower puree and hickory smoked bacon
                  (can be adapted for vegans, also NF)
                </li>
                <li>Teriyaki salmon with wilted Bok Choy</li>
                <li>
                  Pomegranate glazed duck and polenta (GF, can be adapted for
                  VG, also NF)
                </li>
                <li>
                  Buffalo mozzarella with peas, broad beans, mint, lemon and
                  olive oil (v)
                </li>
                <li>Butternut squash soup (V, VG, GF)</li>
              </ul>
            </ServicesPopupListContainer>
            <ServicesPopupListContainer>
              <h3>Mains</h3>
              <ul>
                <li>Chicken Katsu with saag aloo</li>
                <li>
                  Braised duck leg, sweet young peas, lettuce, bacon and mint
                </li>
                <li>
                  Slow cooked beef cheeks, Maris piper mash and glazed carrots
                  (GF, NF, can be adapted for vegans)
                </li>
                <li>
                  Pan-seared Salmon with truffled pea sauce, wild mushrooms
                </li>
                <li>Cod with mustard and tarragon sauce (GF, NF)</li>
                <li>Mushroom risotto with Parmesan and truffle oil (v)</li>
              </ul>
            </ServicesPopupListContainer>
            <ServicesPopupListContainer>
              <h3>Dessert</h3>
              <ul>
                <li>
                  Warm chocolate fondant, salted caramel sauce and vanilla cream
                </li>
                <li>White chocolate and cranberry bread and butter pudding</li>
                <li>Panna cotta with pistachio cream</li>
                <li>Mango and passion fruit cheesecake</li>
                <li>Chocolate brownie with vanilla ice cream</li>
              </ul>
            </ServicesPopupListContainer>
          </ServicesPopupDinnerDescription>
        </Popup>

        <ServicesCard onClick={() => setButtonPopup3(true)}>
          <ServicesIcon src={Icon4} />
          <ServicesTextContainer>
            <ServicesH2>BBQ's</ServicesH2>
            <ServicesP>
              Who doesn’t love a BBQ, a huge selection of meat, fish and
              vegetarian/vegan options, all freshly cooked over restaurant grade
              charcoal. We can even cook an all Vegetarian, or all Vegan BBQ!
            </ServicesP>
          </ServicesTextContainer>
        </ServicesCard>
        <Popup trigger={buttonPopup3} setTrigger={setButtonPopup3}>
          <ServicesPopupTitle>BBQ's</ServicesPopupTitle>
          <ServicesPopupBbqDescription>
            <p>Choose 5 meat / veggie options &amp; Choose 3 salad options</p>
            <ServicesPopupListContainer>
              <h3>Meat options</h3>
              <ul>
                <li>4 oz Steak Burgers</li>
                <li>Pork Sausages</li>
                <li>BBQ chicken thighs (skinless/boneless)</li>
                <li>Peri Peri mini chicken fillets</li>
                <li>Pulled Moroccan lamb with pomegranates</li>
                <li>BBQ pulled pork</li>
                <li>Rump steak slices</li>
                <li>Lamb kofta with mint and yogurt dressing</li>
                <li>Teriyaki salmon bites</li>
              </ul>
            </ServicesPopupListContainer>

            <ServicesPopupListContainer>
              <h3>Our luxury BBQ package includes the following</h3>
              <ul>
                <li>Thick slices of fillet steak</li>
                <li>Thick slices of ribeye steak</li>
                <li>Grilled baby squid</li>
                <li>Half grilled lobster *</li>
                <li>Fresh Slaw</li>
                <li>Char grilled sweet corn</li>
                <li>
                  Steaks served with Chimichurri sauce or peppercorn sauce
                </li>
              </ul>
              <ServicesPopupDisclaimer>
                <p>* surcharge applicable</p>
              </ServicesPopupDisclaimer>
            </ServicesPopupListContainer>
            <ServicesPopupListContainer>
              <h3>Vegetarian / Vegan options</h3>
              <ul>
                <li>Halloumi slices with a tomato jam</li>
                <li>Veggie/vegan burgers</li>
                <li>Vegetable kebabs</li>
                <li>Veggie/vegan sausages</li>
                <li>Char grilled Mediterranean vegetables</li>
              </ul>
            </ServicesPopupListContainer>
            <ServicesPopupListContainer>
              <h3>Salad options</h3>
              <ul>
                <li>Tomato, cucumber, and rocket salad</li>
                <li>Freshly grilled baby gem with Feta and Balsamic glaze</li>
                <li>Fresh Slaw</li>
                <li>Undressed mixed salad leaves</li>
                <li>Pesto pasta</li>
                <li>Charred Corn on the cob bites</li>
                <li>Lemon, pomegranate, and herb couscous </li>
                <li>Potato Salad</li>
              </ul>
            </ServicesPopupListContainer>
          </ServicesPopupBbqDescription>
        </Popup>

        <ServicesCard onClick={() => setButtonPopup4(true)}>
          <ServicesIcon src={Icon3} />
          <ServicesTextContainer>
            <ServicesH2>Canapé & Bowl Food</ServicesH2>
            <ServicesP>
              Looking for something a little more elegant to present to your
              guests, then canapes and bowl food is certainly what you need
            </ServicesP>
          </ServicesTextContainer>
        </ServicesCard>
        <Popup trigger={buttonPopup4} setTrigger={setButtonPopup4}>
          <ServicesPopupTitle>Canapé & Bowl Food</ServicesPopupTitle>
          <ServicesPopupBbqDescription>
            <ServicesPopupListContainer>
              <h3>Canapés</h3>
              {/* A selection of 5 of the below canapés @ £15 pp - each guest will have 2 of each selection */}
              <ul>
                <li>Sausage and mash spoon</li>
                <li>Quail egg and crayfish blini</li>
                <li>Fillet steak skewers with horseradish cream</li>
                <li>Chorizo with roasted butternut squash</li>
                <li>Prosciutto, pear and Gorgonzola rolls</li>
                <li>Feta and basil skewers</li>
                <li>Courgette and parmesan Blini</li>
                <li>Blue cheese, pear and walnut Blini</li>
                <li>Asparagus filo cigar</li>
                <li>Leek and parmesan filo tartlet</li>
                <li>Roasted veg crostini</li>
                <li>Mini baked potatoes with caviar and sour cream</li>
                <li>Teriyaki salmon skewers</li>
              </ul>
            </ServicesPopupListContainer>
            <ServicesPopupListContainer>
              <h3>Bowl food</h3>
              {/* A choice of two bowl foods @ £15 pp */}
              <ul>
                <li>Veggie curry and rice</li>
                <li>Braised beef cheeks with Maris piper mash</li>
                <li>Mini Chicken Katsu curry</li>
                <li>Fish Pie</li>
              </ul>
            </ServicesPopupListContainer>
          </ServicesPopupBbqDescription>
        </Popup>

        <ServicesCard onClick={() => setButtonPopup2(true)}>
          <ServicesIcon src={Icon5} />
          <ServicesTextContainer>
            <ServicesH2>Weddings & Corporate Events</ServicesH2>
            <ServicesP>
              We can provide a three or four course, well-presented meals at
              your venue, this will then be served to you and your guests by our
              team
            </ServicesP>
          </ServicesTextContainer>
        </ServicesCard>
        <Popup trigger={buttonPopup2} setTrigger={setButtonPopup2}>
          <ServicesPopupTitle>Weddings & Corporate Events</ServicesPopupTitle>
          <ServicesPopupDescription>
            From corporate lunches, to buffets, we can cater for you all.
            <br />
            <br />
            You choose from the buffet menu, we will then prepare, cook and
            bring the buffet to your place of work or venue location and lay the
            display out, with clear labels displaying any food allergies. If you
            would like something in particular, we will be more than happy to
            help and provide this for you, further charges may apply depending
            on the dishes you require
            <br />
            <br />
            All of this will be brought in disposable trays, with napkins and
            bio-degradable knives, forks and spoons
            <br />
            <br />
            *************
            <br />
            <br />
            Congratulations on your upcoming wedding!!, if you are looking for a
            caterer for your wedding breakfast or even your wedding reception,
            we would be more than happy to help
            <br />
            <br />
            We can provide a three or four course, well-presented meal at your
            venue, this will then be served to you and your guests by our team.
            If you have any special requests or dietary requirements, not a
            problem, we are happy to cater for them all
            <br />
            <br />
            Please note:  We will need the use of the venue kitchen to be able
            to facilitate the wedding breakfast and possibly the reception,
            depending on your requests.  If you would like a BBQ, the use of the
            kitchen will not be required
            <br />
            <br />
            For more information, please complete the{" "}
            <NavLinksRouter
              to="/contact-us/"
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              enquiries
            </NavLinksRouter>{" "}
            page for details
          </ServicesPopupDescription>
        </Popup>
      </ServicesWrapper>
    </ServicesContainter>
  );
};

export default Services;
