import React from "react";
import { useLocation } from "react-router-dom";
import {
  SidebarContainer,
  CloseIcon,
  Icon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarBtnRoute,
  SidebarRoute,
} from "./SidebarElements";
import { FaTimes } from "react-icons/fa";

const Sidebar = ({ isOpen, toggle }) => {
  let sidebarNav;

  const location = useLocation();

  if (location.pathname === "/" || location.pathname === "/home") {
    sidebarNav = (
      <SidebarMenu>
        <SidebarLink to="about" onClick={toggle}>
          About
        </SidebarLink>
        <SidebarLink to="services" onClick={toggle}>
          Services
        </SidebarLink>
        <SidebarLink to="reviews" onClick={toggle}>
          Reviews
        </SidebarLink>
        <SidebarRoute to="gallery" onClick={toggle}>
          Gallery
        </SidebarRoute>
        {/* <SidebarLink to='contact' onClick={toggle}>Contact</SidebarLink> */}
      </SidebarMenu>
    );
  } else {
    sidebarNav = (
      <SidebarMenu>
        <SidebarRoute to="/#about" onClick={toggle}>
          About
        </SidebarRoute>
        <SidebarRoute to="/#services" onClick={toggle}>
          Services
        </SidebarRoute>
        <SidebarRoute to="/#reviews" onClick={toggle}>
          Reviews
        </SidebarRoute>
        <SidebarRoute to="/gallery" onClick={toggle}>
          Gallery
        </SidebarRoute>
        {/* <SidebarRoute to='/#contact' onClick={toggle}>Contact</SidebarRoute> */}
      </SidebarMenu>
    );
  }

  return (
    <SidebarContainer id="sidebar" isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {sidebarNav}
        <SideBtnWrap>
          <SidebarBtnRoute to="/contact-us/">Book here</SidebarBtnRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
