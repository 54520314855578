import React from "react";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import Services from "../components/Services";
import Recommendations from "../components/Recommendations";

const Home = () => {
  return (
    <>
      <HeroSection />
      <InfoSection />
      <Services />
      <Recommendations />
    </>
  );
};

export default Home;
