import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { Data } from "../Faq/Data";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";
import {
  FaqSection,
  FaqContainer,
  QuestionWrap,
  AnswerDropdown,
  FaqWrap,
} from "../Faq/FaqElements";

import "./index.css";

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const form = useRef();

  const [value, setValue] = useState("default");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_m6iqb25",
        "template_j6pj18h",
        form.current,
        "U5_QJfzHkK5m6FGhr"
      )
      .then(
        function (response) {
          alert(
            "Thank you, I shall get back to you as soon as possible\n\nMartin"
          );
          window.location.reload(false);
        },
        function (error) {
          alert("Failed to send the message, please try again");
        }
      );
  };

  // FAQ section
  const [clicked, setClicked] = useState(false);
  const toggleFaq = (item) => {
    if (clicked === item) {
      return setClicked(null);
    }
    setClicked(item);
  };

  const qanda = Data.slice(1, 4).map((item) => (
    <div key={item.id}>
      <div className="faq-wrap">
        <div className="question-wrap" onClick={() => toggleFaq(item)}>
          <span>Q.</span>
          <h5>{item.question}</h5>
          <span>{clicked === item ? <FiMinus /> : <FiPlus />}</span>
        </div>
        {clicked === item ? (
          <div className="answer-dropdown">
            <p>{item.answer}</p>
          </div>
        ) : null}
      </div>
    </div>
  ));

  return (
    <>
      <div className="contact-form">
        <div className="column1">
          {/* CENTRE THIS */}
          <h2 className="contact-title">Booking Enquiry</h2>

          {/* FAQ SECTION */}
          <div className="faq-section">
            {qanda}
            <a href="/faqs/">
              <span>See more FAQs</span>
            </a>
            <br />
            <br />
            <h4>
              Hopefully our FAQs will give you the answer. If not feel free to
              get in touch!
            </h4>
          </div>
        </div>
        <div className="column2">
          <form ref={form} onSubmit={sendEmail}>
            <ul>
              <li>
                <input
                  placeholder="Full Name"
                  type="text"
                  name="from_name"
                  required
                />
              </li>
              <li>
                <input
                  placeholder="Email Address"
                  type="email"
                  name="from_email"
                  required
                />
              </li>
              <li>
                <input
                  placeholder="Subject"
                  type="text"
                  name="subject"
                  required
                />
              </li>
              <li>
                <select
                  name="category"
                  value={value}
                  onChange={handleChange}
                  required
                >
                  <option value="default" disabled hidden>
                    Choose a category
                  </option>
                  <option value="bbq">BBQ</option>
                  <option value="canape/ bowl food">Canapé / Bowl Food</option>
                  <option value="private-dining">Private Dining</option>
                  <option value="wedding">Wedding</option>
                  <option value="corporate">Private / Corporate Events</option>
                  <option value="other">Other</option>
                </select>
              </li>
              <li>
                <textarea
                  placeholder="Message"
                  name="message"
                  required
                ></textarea>
              </li>
              <ReCAPTCHA
                sitekey="6LdKS84jAAAAALzFlsP7txic36BKwNGmTp01Z2an"
                onChange={onChange}
              />
              <br />
              <li id="contact-submit">
                <input type="submit" className="flat-button" value="SEND" />
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
