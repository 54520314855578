import React from "react";
import {
  FooterContainer,
  FooterWrapper,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLink,
  SocialMedia,
  SocialMediaWrapper,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  FooterLogoWrapper,
  FooterLogo,
  FooterLinkPhone,
} from "./FooterElements";

import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import logoImage from "../../images/cd-logo.svg";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterWrapper>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLink
                  to="/contact-us/"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  Contact Us
                </FooterLink>

                <FooterLink
                  to="/faqs/"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  FAQ's
                </FooterLink>
                <FooterLinkPhone href="tel:07985133847">
                  07985133847
                </FooterLinkPhone>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrapper>
              <FooterLogoWrapper>
                <FooterLogo src={logoImage} />
              </FooterLogoWrapper>

              <WebsiteRights>
                Convivio © {new Date().getFullYear()} All right reserved
              </WebsiteRights>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/convivodining"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/convivio_dining/?hl=en"
                  target="_blank"
                  aria-label="Instgram"
                >
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href="https://twitter.com/conviviodining"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <FaTwitter />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrapper>
          </SocialMedia>
        </FooterWrapper>
      </FooterContainer>
    </>
  );
};

export default Footer;
