import React, { useState } from "react";
import Portfolio from "../components/Gallery";

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Portfolio />
    </>
  );
};

export default Gallery;
