import React, { useState } from "react";
import { Data } from "./Data";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";
import {
  FaqPageWrapper,
  FaqSection,
  FaqContainer,
  QuestionWrap,
  AnswerDropdown,
  FaqWrap,
} from "./FaqElements";

const Faq = () => {
  const [clicked, setClicked] = useState(false);
  const toggle = (item) => {
    if (clicked === item) {
      return setClicked(null);
    }
    setClicked(item);
  };

  return (
    <IconContext.Provider value={{ color: "#fff", size: "25px" }}>
      <FaqPageWrapper>
        <h1>Frequently Asked Questions (FAQ)</h1>
        {/* <h2>Frequently Asked Questions (FAQ)</h2> */}
        <FaqSection>
          <FaqContainer>
            {Data.map((item) => (
              <div key={item.id}>
                <FaqWrap>
                  <QuestionWrap onClick={() => toggle(item)}>
                    <h1>
                      <span>Q.</span>
                      {item.question}
                    </h1>
                    <span>{clicked === item ? <FiMinus /> : <FiPlus />}</span>
                  </QuestionWrap>
                  {clicked === item ? (
                    <AnswerDropdown>
                      <p>{item.answer}</p>
                    </AnswerDropdown>
                  ) : null}
                </FaqWrap>
              </div>
            ))}
          </FaqContainer>
        </FaqSection>
      </FaqPageWrapper>
    </IconContext.Provider>
  );
};

export default Faq;
